import React, { useState } from 'react';
import '../styles/App.sass';
import {
    Footer,
    Header,
    Funciones,
    Notas,
    Intro,
    // Spotify,
    // Map,
    // Patrocinantes,
    // Productoras,
    // CountDown,
    // PuntoDeVentas,
    // MetodosDePagos,
    Terminos,
    Galeria
    // Snacks,
} from '../components';
// import { Help } from '../components/iconSvg';
// import { Link } from 'react-router-dom';
// import moment from 'moment-timezone';

const Home = () => {
    const MONEDAS = ['USD', 'VES'];
    // const MONEDAS = ['USD'];
    const [moneda, setMoneda] = useState(MONEDAS[0]);
    const [allowBuy] = useState(true);
    // const DIFF_HOR_SEC = moment(
    //     moment().format('DD/MM/YYYY hh:mm a'), 'DD/MM/YYYY hh:mm a'
    // ).diff(
    //     moment(
    //         moment().tz('America/Caracas').format('DD/MM/YYYY hh:mm a'), 'DD/MM/YYYY hh:mm a'
    //     ),
    //     'seconds'
    // );

    return (
        <>
            <Header
                urlBanner={`${process.env.REACT_APP_URL_IMAGES}banner-desktop.png`}
                urlBannerMovil={`${process.env.REACT_APP_URL_IMAGES}banner-movil.png`}
            />
            <div className='pt-5'>
                <div className="container-custom-tm mb-5">
                    {/* <CountDown
                        date={moment('15/10/2023 11:00 am', 'DD/MM/YYYY hh:mm a').add(
                            DIFF_HOR_SEC,
                            'seconds'
                        )}
                        onFinish={() => setAllowBuy(true)}
                    /> */}
                    <div className="row ">
                        <div className="col-12 col-xl-6 d-flex flex-column mb-4">
                            <Intro intro={
                                <p>El Reno de la Nariz Roja enciende la Navidad en Venezuela. ¡Un espectáculo mágico llega a tu ciudad! Disfruta de un clásico navideño como nunca antes lo habías visto. El Reno de la Nariz Roja, acompañado de Santa Claus, el Grinch, Elsa, Blanca Nieves y muchas sorpresas más, te llevarán en un viaje lleno de aventuras y fantasía. Una producción al estilo de Broadway que te hará vivir la magia de la Navidad como un niño. ¡No te lo puedes perder!</p>
                            }/>
                        </div>
                        <div className="col-12 col-xl-6 d-flex flex-column mb-4">
                            <Notas
                                notas={[
                                    'Prohibido el ingreso de bebidas alcohólicas, aguas, comidas, chucherías, el consumo es exclusivo en las áreas internas del recinto.',
                                    'Prohibido el ingreso de cualquier tipo de arma (Incluye navajas, spray de pimienta, pirotecnia, e.t.c).',
                                    'No ingresar con cámaras fotográficas profesionales, cámaras de video, o equipos de grabación de audio (nada con lentes desmontables, trípodes, zooms grandes, etc).',
                                    'No está permitido ingresar con sombrillas o paraguas.'
                                ]}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <Funciones
                            moneda={moneda}
                            setMoneda={setMoneda}
                            monedas={MONEDAS}
                            allowBuy={allowBuy}
                        />
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12 mb-4">
                            <Galeria/>
                        </div>
                        {/* <div className="col-12 col-md-12 col-lg-4 mb-4">
                            <Spotify inline={true} Spotifyurl="https://open.spotify.com/embed/artist/5v3TYN6Mmi5vxV2uSOfXot?utm_source=generator"/>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex mb-4 ">
                            <Terminos texto={
                                <>
                                    1. Las entradas adquiridas son responsabilidad de la persona que efectúa la compra. En caso de mal uso o distribución de información de la compra a terceros, se exime de total responsabilidad a TICKETMUNDO.<br/>
                                    2. El poseedor del ticket se obliga a cumplir las normas de seguridad aplicables al evento o recinto.<br/>
                                    3. Deberá canjear este boleto electrónico por ticket físicos a petición exclusiva del productor del evento si fuese necesario. En estos casos será notificado del debido proceso con antelación vía correo electrónico y/o nuestras redes sociales. Si el titular de la compra no puede retirar la(s) entrada(s) para asistir al evento, puede realizar una autorización a terceros para realizar el canje de la misma. <a href='https://cdn.ticketmundo.live/documentos/autorizacion-de-canje-ticketmundo.pdf' target='_blank' rel='noreferrer' style={{ color: `var(--bg__btn)` }}>https://cdn.ticketmundo.live/documentos/autorizacion-de-canje-ticketmundo.pdf</a><br/>
                                    4. Una vez realizada la compra de los boletos, los mismos no pueden ser cambiados, ni solicitar reembolso por la compra.<br/>
                                    5. El productor(es) y/o organizador (es) se reservan el derecho de admisión y permanencia en el recinto de cualquier persona que perturbe la experiencia del evento por un comportamiento que altere su normal desarrollo, al igual que comida, bebidas u otros artículos prohibidos por el productor.<br/>
                                    6. En caso de cancelación del espectáculo, será responsabilidad de la empresa productora la correspondiente devolución del monto pagado por las entradas, dicho monto no incluirá las comisiones cobradas por TICKETMUNDO.<br/>
                                    7. En caso de compras con tarjetas internacionales, deberá enviar a través del correo de <a href="mailto:verificacion.compras@ticketmundo.com" rel="noreferrer" style={{ color: `var(--bg__btn)` }}>verificacion.compras@ticketmundo.com</a>, los siguientes recaudos para la validación de la compra: Foto de la cédula de identidad, pasaporte u otro documento de identificación. Imagen de la tarjeta utilizada para la compra, mostrando solo los 4 últimos dígitos. Planilla de validación: <a href="https://cdn.ticketmundo.live/documentos/Ticketmundo-Carta-Aceptacion-Cargo-Tarjeta-De-Credito-Forma-PV001.pdf" target="_blank" rel="noreferrer" style={{ color: `var(--bg__btn)` }}>https://cdn.ticketmundo.live/documentos/Ticketmundo-Carta-Aceptacion-Cargo-Tarjeta-De-Credito-Forma-PV001.pdf</a>. En caso de consultas comunicarse a través del whatsapp AT <a href="https://wa.me/+584124286237" target="_blank" rel="noreferrer" style={{ color: `var(--bg__btn)` }}>+58 (412) 428-62-37</a><br/>
                                    8. Si por razones de fuerza mayor, no imputable al recinto o teatro, el día del evento la función se suspende, habiendo sido en parte presentada, no se realizará devolución alguna del valor de la entrada.<br/>
                                    9. Menores de 18 años, deben de venir acompañados por un representante mayor de edad.<br/>
                                    10. No se admite el ingreso de niños menores a 3 años<br/>
                                    11. Todo niño a partir de 3 años debe adquirir ticket para ingresar al evento<br/>
                                    12. Las compras en la Web podrán requerir canje por ticket físico según solicitud de la producción. TICKETMUNDO notificará la fecha de canje y lugares.<br/>
                                </>
                            }/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Home;
